import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    FormControl, FormLabel, FormErrorMessage, FormHelperText, Checkbox, CheckboxGroup,
    Text, Image, Input, Stack, Box, Link, Button, Flex, RadioGroup,
    Radio, Textarea, Heading, Tooltip, CircularProgress, CircularProgressLabel
} from '@chakra-ui/react'

import { ChevronDownIcon, ChevronRightIcon, InfoIcon } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { AiOutlineReload } from "react-icons/ai"
import { MdContentCopy } from "react-icons/md"
import { ChipInput } from '../../sections/Chip';
import PageContent from './PageContent/PageContent'
import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import copy from 'copy-to-clipboard';

import { API_URL } from "../../../constants"
import useAxios from "../../../utils/useAxios"

const AIGeneration = ({ generate }) => {
    const [progress, setProgress] = useState(0);
    const [userDataContext, setUserDataContext] = useOutletContext();
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const AIGenerationSchema = Yup.object().shape({
        product_title: Yup.string().required('Required'),
        // product_features: Yup.string().required('required'),
        product_bullets_1: Yup.string().optional(),
        product_bullets_2: Yup.string().optional(),
        product_bullets_3: Yup.string().optional(),
        product_bullets_4: Yup.string().optional(),
        product_bullets_5: Yup.string().optional(),
        product_features: Yup.string().when('product_bullets_1', (product_bullets_1, schema) => {
            const product_bullets_2 = schema.fields?.product_bullets_2;
            const product_bullets_3 = schema.fields?.product_bullets_3;
            const product_bullets_4 = schema.fields?.product_bullets_4;
            const product_bullets_5 = schema.fields?.product_bullets_5;
            return (product_bullets_1 || product_bullets_2 || product_bullets_3 || product_bullets_4 || product_bullets_5) ? Yup.string().optional() : Yup.string().required('Required');
        }), 
        // product_seo_keywords: Yup.array().min(3, 'atleast 3 keywords required').required('required'),
    });
    const [Overlay, setOverlay] = useState(false); 
    const toast = useToast();

    const api = useAxios();
    const params = useParams();
    let navigate = useNavigate();
    let location = useLocation();

    const [textForCopy, setTextForCopy] = useState('');
    const handleListingTextData = (data) => {
        
        let textToCopy = '';
        if (data['id']) {
            textToCopy += 'Product Title:\n' + data['generated_title'] + '\n\n\n';
            textToCopy += 'Bullet Points:\n' + data['generated_bullets_text'] + '\n\n';
            if (data['generated_production_description_text'].length > 0) {
                textToCopy += 'Product Description:\n' + data['generated_production_description_text'] + '\n';
            }
            if (data['generated_production_benefits_text'].length > 0) {
                textToCopy += 'Benefits:\n' + data['generated_production_benefits_text'];
            }
            setTextForCopy(textToCopy);
        }
    }

    const copyText = (event) => {
        event.preventDefault(); 
        let text = textForCopy;
        copy(text, {
            debug: true,
            message: 'Press #{key} to copy',
        });
        toast({
            position: 'top',
            title: 'Text copied',
            status: 'success',
            duration: 9000,
            isClosable: true,
        })
    }
    
    useEffect(() => {
        if (progress < 100 && Overlay) {
            setTimeout(() => {
                setProgress(progress + 1);
            }, 600)
        } else {
            // setOverlay(false);
            setProgress(0);
        }
    }, [progress, Overlay])

    let [res, setRes] = useState({
        'id' : 0,
        'product_title' : '', //'Fleur de Sel Sea Salt',
        'product_brand' : '', //'Tablissima',
        'product_features' : '', //'Sea Salt, Fleur de Sel, Caviar of Salt, Made in France, Trusted by the best chefs',
        'product_seo_keywords' : [], //'Fleur de Sel,  Hand Harvested', 
        'product_bullets_1' : '', //'Hand Harvested',
        'product_bullets_2' : '', //'Artisanal Salt',
        'product_bullets_3' : '', //'Caviar of Salt',
        'product_bullets_4' : '', //'France',
        'product_bullets_5' : '', //'Quality cork box',
        'product_bullet_points_lenght' : 'large',
        'product_bullet_points_emoji' : 'none',
        'product_description_emoji' : 'none',
        'generated_title' : '',
        'generated_bullets' : [],
        'generated_production_description' : [],
        'generated_production_benefits' : [],
        'generated_full_text' : '',
        'updateAction' : ''
    });

    useEffect(() => {   
        if (userDataContext != undefined && userDataContext['profile_data'] != undefined) {
            if (userDataContext['profile_data']['groups'] != undefined) {
                if (userDataContext['profile_data']['groups'].indexOf('Debug') != -1) {
                    res['debug_mode'] = true;
                    if (!params['textId']) {
                        // res['product_title'] = 'Fleur de Sel Sea Salt';
                        // res['product_brand'] = 'Tablissima';
                        // res['product_features'] = 'Sea Salt, Fleur de Sel, Caviar of Salt, Made in France, Trusted by the best chefs';
                        // res['product_seo_keywords'] = ['Fleur de Sel', 'Best choice', 'Hand Harvested'];
                        // res['product_bullets_1'] = 'Hand Harvested';
                        // res['product_bullets_2'] = 'Artisanal Salt';
                        // res['product_bullets_3'] = 'Caviar of Salt';
                        // res['product_bullets_4'] = 'France';
                        // res['product_bullets_5'] = 'Quality cork box';
                        res['product_title'] = 'Charcoal Exfoliating';
                        res['product_brand'] = 'M3 Naturals';
                        res['product_features'] = 'M3 Naturals Activated Charcoal Body Polish Scrub provides a quality exfoliating experience as a body scrub, face scrub, hand scrub, or foot scrub! Working together, our natural charcoal granules and coconut oil will help restore your natural skin glow and wash away those unwanted toxins and dead skin cells! Infused with Collagen and Stem Cell our moisturizing charcoal scrub will take your skin care routine to the next level by boosting natural collagen production and increasing skin cell longevity.';
                        res['product_seo_keywords'] = ['Hydrating', 'Exfoliating', 'Blemish control', 'Anti-acne'];
                        res['product_bullets_1'] = 'Exfoliating';
                        res['product_bullets_2'] = 'Blemish control';
                        res['product_bullets_3'] = 'Detoxifying';
                        res['product_bullets_4'] = 'Anti-aging';
                        res['product_bullets_5'] = 'Hydrating';
                        setRes(res);
                    }
                }
            }
        }  
    }, [userDataContext, location.pathname]);

    let disabled = (params['textId']) ? true : false;

    useEffect(() => {
        if (params['textId']) {
            
            disabled = (params['textId']) ? true : false;
            
            generate = false;
            
            const fetchData = async () => {
                try {
                    setOverlay(true);
                    api.get(API_URL + params['textId']).then((response) => {
                        // console.log(response.data);

                        let product_bullets = JSON.parse(response.data['product_bullets']);
                        
                        response.data['product_bullets_1'] = product_bullets[0];
                        response.data['product_bullets_2'] = product_bullets[1];
                        response.data['product_bullets_3'] = product_bullets[2];
                        response.data['product_bullets_4'] = product_bullets[3];
                        response.data['product_bullets_5'] = product_bullets[4];
                        
                        response.data['product_seo_keywords'] = JSON.parse(response.data['product_seo_keywords']);
                        response.data['generated_bullets'] = JSON.parse(response.data['generated_bullets']);
                        response.data['generated_production_description'] = JSON.parse(response.data['generated_production_description']);
                        response.data['generated_production_benefits'] = JSON.parse(response.data['generated_production_benefits']);
                        setRes(response.data);
                        setOverlay(false);
                    }).catch(function (error) {

                        if (error == undefined) {
                            toast({
                                position: 'top',
                                title: 'Something went wrong',
                                description: "Please, try again",
                                status: 'error',
                                duration: 9000,
                                isClosable: true,
                            })

                        } else if (error.response != undefined && error.response.status != 404) {
                            navigate('/listings/');
                        } else {
                            toast({
                                position: 'top',
                                title: 'Something went wrong',
                                description: "Please, try again",
                                status: 'error',
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                        setOverlay(false);
                    });

                } catch(error) {
                    toast({
                        position: 'top',
                        title: 'Something went wrong',
                        description: "Please, try again",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                    setOverlay(false);
                }
            };
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params['textId'], location.pathname]);

     
    const createItem = data => {
        // console.log(data);
        setOverlay(true);

        try {
            if (data['id'] != undefined) delete data['id'];

            api.post(API_URL, data).then((result) => {
                // console.log(result['data']);
                let dataCreated = {
                    ...result['data']
                };
                // setRes(dataCreated);
                navigate("/listings/" + result['data']['custom_id'] + '/');
                setOverlay(false);
            }).catch((error) => {
                
                let errorText = 'Please, try again';
                let title = 'Something went wrong';
                if (error.response.data.error && error.response.data.error == 'No credits') {
                    toast({
                        position: 'top',
                        status: 'error',
                        containerStyle: {
                            width: '380px',
                            maxWidth: '100%'
                        },
                        duration: 9000,
                        render: () => (
                            <Box color='white' p={3} bg='red.500' textAlign={'center'} fontSize={'16px'}>
                                <p style={{fontSize:'18px'}}><b>You've ran out of credit</b></p>
                                <p><a href="/account/upgrade/" style={{textDecoration:'underline'}}>Upgrade now</a> to run some more AI</p>
                            </Box>
                        ),
                    })
                } else {
                    if (error.response.data.error) errorText = error.response.data.error;
                    else {
                        const errorsKeys = Object.keys(error.response.data);
                        if (errorsKeys.indexOf('product_title') != -1) {
                            title = '"Product / Listing Name" is required';
                            errorText = 'Please, fill it and try again'
                        } else if (errorsKeys.indexOf('product_features') != -1) {
                            title = '"Product features" is required';
                            errorText = 'Please, fill it and try again'
                        }
                    }
                    toast({
                        position: 'top',
                        title: title,
                        description: errorText,
                        status: 'error',
                        containerStyle: {
                            width: '400px',
                            maxWidth: '100%',
                        },
                        duration: 9000,
                        isClosable: true,
                    })
                }
                setOverlay(false);
            });
        } catch(error) {
            toast({
                position: 'top',
                title: 'Something went wrong',
                description: "Please, try again",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            setOverlay(false);
        }
    };

    return (
        <Flex w="100%" direction={{ sm: "column", md: "row" }} position="relative">
            <Flex display={Overlay ? "flex" : "none"} alignItems="flex-start" justifyContent="center" w="100%" h="100%" bgColor="#FFFFFFBF" position="absolute" top="0" zIndex={2}>
                <Box height="100vh" display="flex" flexDirection="row" alignItems="center">
                    <CircularProgress value={progress} color='#5A0505' size={55}>
                        <CircularProgressLabel>{progress}%</CircularProgressLabel>
                    </CircularProgress>
                </Box>
            </Flex>
            <Box w={{ sm: "90%", md: "35%" }} my="60px" px={10} mx={{ sm: "auto", md: 0 }} borderRightWidth="1px">
                <Flex direction="row" align="center" mb="30px">
                    <Image src='/assets/FiSettings.svg' mr={2} />
                    <Heading>AI Generation</Heading>
                </Flex>
                <Formik
                    enableReinitialize
                    initialValues={res}
                    validationSchema={AIGenerationSchema}
                    onSubmit={(values) => {
                        // console.log(res);
                        if (disabled) return null;
                        createItem(values);
                    }}>

                    {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormControl w="100%" mb={3}>
                                <FormLabel color="#000" fontWeight="bold" display="flex" flexDirection="row" alignItems="center">
                                    Product / Listing Name:
                                    <Tooltip hasArrow placement='top-start' openDelay={300} label='Name of your Product (Without Brand)' fontSize='md'>
                                        <Box><InfoIcon mx={1} boxSize={3} alignSelf="center" /> </Box>
                                    </Tooltip>
                                </FormLabel>
                                <Input isDisabled={disabled} variant='filled' placeholder='Fleur de Sel Sea Salt' name='product_title' onChange={handleChange} value={values.product_title} />
                                {errors.product_title && touched.product_title ? (<Text fontSize={14} color="#ff0000">{errors.product_title}</Text>) : null}
                            </FormControl>
                            <FormControl w="100%" mb={3}>
                                <FormLabel color="#000" fontWeight="bold" display="flex" flexDirection="row" alignItems="center">
                                    Brand: [Optional]
                                    <Tooltip hasArrow placement='top-start' openDelay={300} label='Brand Name' fontSize='md'>
                                        <Box><InfoIcon mx={1} boxSize={3} alignSelf="center" /> </Box>
                                    </Tooltip>
                                </FormLabel>
                                <Input isDisabled={disabled} variant='filled' placeholder='Tablissima' name='product_brand' onChange={handleChange} value={values.product_brand} />
                            </FormControl>
                            <FormControl w="100%" mb={3}>
                                <FormLabel color="#000" fontWeight="bold" display="flex" flexDirection="row" alignItems="center">
                                    Product Features:
                                    <Tooltip hasArrow placement='top-start' openDelay={300} label='Features of your products separated by commas.' fontSize='md'>
                                        <Box><InfoIcon mx={1} boxSize={3} alignSelf="center" /> </Box>
                                    </Tooltip>
                                </FormLabel>
                                <Textarea isDisabled={disabled} maxLength="500" variant='filled' placeholder='Sea Salt, Fleur de Sel, Caviar of Salt, Made in France, Trusted by the best chefs' name='product_features' onChange={handleChange} value={values.product_features} h={20} />
                                {errors.product_features && touched.product_features ? (<Text fontSize={14} color="#ff0000">{errors.product_features}</Text>) : null}
                            </FormControl>
                            <FormControl w="100%" mb={3}>
                                <FormLabel color="#000" fontWeight="bold" display="flex" flexDirection="row" alignItems="center">
                                    SEO Keywords: [Optional]
                                    <Tooltip hasArrow placement='top-start' openDelay={300} label='SEO Keywords that you would like to use for your listing. Please enter to validate.' fontSize='md'>
                                        <Box><InfoIcon mx={1} boxSize={3} alignSelf="center" /> </Box>
                                    </Tooltip>
                                </FormLabel> 
                                <ChipInput disabled={disabled} resValues={res.product_seo_keywords} initialSeos={values.product_seo_keywords} name="product_seo_keywords" onChange={handleChange} setFieldValue={setFieldValue} />
                                {errors.product_seo_keywords && touched.product_seo_keywords ? (<Text fontSize={14} color="#ff0000">{errors.product_seo_keywords}</Text>) : null}
                            </FormControl>
                            <Flex direction="row" align="center" my={7} cursor="pointer" onClick={() => { setShowAdvancedOptions(!showAdvancedOptions) }}>
                                <Flex direction="row" align="center">
                                    {showAdvancedOptions ?
                                        <ChevronDownIcon mr={1} boxSize={5} />
                                        : <ChevronRightIcon mr={1} boxSize={5} />}
                                    <Text fontWeight="bold"> Advanced [Optional]</Text>
                                </Flex>
                                <Tooltip hasArrow placement='top-start' openDelay={300} label='Advanced Options' fontSize='md'>
                                    <Box><InfoIcon mx={1} boxSize={3} alignSelf="center" /> </Box>
                                </Tooltip>
                            </Flex>
                            {showAdvancedOptions &&
                                <>
                                    <FormControl w="100%" >
                                        <FormLabel color="#909090" fontWeight="bold" display="flex" flexDirection="row" alignItems="center">
                                            Bullet Points Focus: [Optional]
                                            <Tooltip hasArrow placement='top-start' openDelay={300} label='Focus' fontSize='md'>
                                                <Box><InfoIcon mx={1} boxSize={3} alignSelf="center" /> </Box>
                                            </Tooltip>
                                        </FormLabel>
                                        <Stack spacing={3}>
                                            <Input isDisabled={disabled} variant='filled' placeholder='Hand Harvested' name="product_bullets_1" onChange={handleChange} value={values.product_bullets_1} />
                                            <Input isDisabled={disabled} variant='filled' placeholder='Artisanal Salt' name="product_bullets_2" onChange={handleChange} value={values.product_bullets_2} />
                                            <Input isDisabled={disabled} variant='filled' placeholder='Caviar of Salt' name="product_bullets_3" onChange={handleChange} value={values.product_bullets_3} />
                                            <Input isDisabled={disabled} variant='filled' placeholder='France' name="product_bullets_4" onChange={handleChange} value={values.product_bullets_4} />
                                            <Input isDisabled={disabled} variant='filled' placeholder='Quality cork box' name="product_bullets_5" onChange={handleChange} value={values.product_bullets_5} />
                                        </Stack>
                                    </FormControl>
                                    <FormControl w="100%" my={7}>
                                        <FormLabel color="#909090" fontWeight="bold" display="flex" flexDirection="row" alignItems="center">
                                            Bullet Points Length: [Optional]
                                            <Tooltip hasArrow placement='top-start' openDelay={300} label='Length' fontSize='md'>
                                                <Box><InfoIcon mx={1} boxSize={3} alignSelf="center" /> </Box>
                                            </Tooltip>
                                        </FormLabel>
                                        <RadioGroup isDisabled={disabled} defaultValue={values.product_bullet_points_lenght}>
                                            <Flex direction={{ sm: "column", md: "row" }}>
                                                <Radio value='small' name="product_bullet_points_lenght" onChange={() => setFieldValue("product_bullet_points_lenght", 'small')} mr={7}>
                                                    150 Char.
                                                </Radio>
                                                <Radio value='medium' name="product_bullet_points_lenght" onChange={() => setFieldValue("product_bullet_points_lenght", 'medium')} mr={7}>
                                                    250 Char.
                                                </Radio>
                                                <Radio value='large' name="product_bullet_points_lenght" onChange={() => setFieldValue("product_bullet_points_lenght", 'large')} mr={7}>
                                                    300 Char.
                                                </Radio>
                                            </Flex>
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl w="100%" mb={7}>
                                        <FormLabel color="#909090" fontWeight="bold" display="flex" flexDirection="row" alignItems="center">
                                            Bullet Points Emoji : [Optional]
                                            <Tooltip hasArrow placement='top-start' openDelay={300} label='Emoji' fontSize='md'>
                                                <Box><InfoIcon mx={1} boxSize={3} alignSelf="center" /> </Box>
                                            </Tooltip>
                                        </FormLabel>
                                        <RadioGroup isDisabled={disabled} defaultValue={values.product_bullet_points_emoji}>
                                            <Flex direction={{ sm: "column", md: "row" }}>
                                                <Radio value='none' name="product_bullet_points_emoji" onChange={() => setFieldValue("product_bullet_points_emoji", 'none')} mr={7}>
                                                    None
                                                </Radio>
                                                <Radio value='tick' name="product_bullet_points_emoji" onChange={() => setFieldValue("product_bullet_points_emoji", 'tick')} mr={7}>
                                                    Tick (✔️)
                                                </Radio>
                                                <Radio value='ai' name="product_bullet_points_emoji" onChange={() => setFieldValue("product_bullet_points_emoji", 'ai')} mr={7}>
                                                    AI Generated
                                                    (⭐ 🇧🇷🇮🇹)
                                                </Radio>
                                            </Flex>
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl w="100%" mb={7}>
                                        <FormLabel color="#909090" fontWeight="bold" display="flex" flexDirection="row" alignItems="center">
                                            Descriptions Emoji : [Optional]
                                            <Tooltip hasArrow placement='top-start' openDelay={300} label='Descriptions Emoji' fontSize='md'>
                                                <Box><InfoIcon mx={1} boxSize={3} alignSelf="center" /> </Box>
                                            </Tooltip>
                                        </FormLabel>
                                        <RadioGroup isDisabled={disabled} defaultValue={values.product_description_emoji}>
                                            <Flex direction={{ sm: "column", md: "row" }}>
                                                <Radio value='none' name="product_description_emoji" onChange={() => setFieldValue("product_description_emoji", 'none')} mr={7}>
                                                    None
                                                </Radio>
                                                <Radio value='tick' name="product_description_emoji" onChange={() => setFieldValue("product_description_emoji", 'tick')} mr={7}>
                                                    Tick (✔️)
                                                </Radio>
                                                <Radio value='ai' name="product_description_emoji" onChange={() => setFieldValue("product_description_emoji", 'ai')} mr={7}>
                                                    AI Generated
                                                    (⭐ 🇧🇷🇮🇹)
                                                </Radio>
                                            </Flex>
                                        </RadioGroup>
                                    </FormControl>
                                </>
                            }

                            {generate && 
                                <Button type='submit' colorScheme='brand' w="fit-content" mb={10}>
                                    Generate a Listing with AI
                                </Button>
                            }

                            {!generate && 
                                <Button type='button' onClick={(event) => copyText(event)} colorScheme='brand' w="fit-content" mb={10}>
                                    <Flex direction="row" align="center">
                                        <Image src='/assets/buttonIcon.svg' alt='' mx={3} />
                                        <Text mb={1}>Copy this Listing</Text>
                                        <Image src='/assets/buttonIcon.svg' alt='' mx={3} />
                                    </Flex>
                                </Button>
                            }
                            <Stack spacing={1} display="flex" alignItems="flex-start">
                                <Flex direction="row" align="center">
                                    <Icon as={AiOutlineReload} color="#7182A4" mr={2} />
                                    <Text color="#7182A4">Generate New content</Text>
                                </Flex>
                                <Flex direction="row" align="center">
                                    <Icon as={MdContentCopy} color="#7182A4" mr={2} />
                                    <Text color="#7182A4">Copy and Paste</Text>
                                </Flex>
                            </Stack>
                        </Form>

                    )}
                </Formik>
            </Box>
            {/* <Box w="50px" mt="60px" bgGradient='linear(to-r, #fff, #fafafa, #ddd)' /> */}
            <PageContent generate={generate} context={res} setOverlay={setOverlay} onData={handleListingTextData}/>
        </Flex >
    )
}

export default AIGeneration