import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Link,
    useColorModeValue,
    useDisclosure,
    Image,
} from '@chakra-ui/react';
import {
    HamburgerIcon,
    CloseIcon,
} from '@chakra-ui/icons';

import { Link as RouteLink, useLocation } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import { useEffect } from 'react';
import useAxios from "../../utils/useAxios"
import { useState } from 'react';

export default function Header(props) {
    
    const userData = props.props;
    const location = useLocation();
    const api = useAxios();
    const showHeader = (location.pathname == '/login/' || location.pathname == '/signup/' || location.pathname == '/reset-password/') ? false : true;

    const { user, logoutUser } = useAuth();
    const linkColor = useColorModeValue('white', 'black');
    const bgColor = useColorModeValue('black', 'white');
    const borderColor = useColorModeValue('gray.200', 'gray.900');
    const { isOpen, onToggle } = useDisclosure();
    const [Overlay, setOverlay] = useState(false); 
    const [ checkoutUrl, setCheckoutUrl ] = useState(null);
    
    useEffect(() => {
        if (!checkoutUrl) return;
        window.location.href = checkoutUrl;
    }, [checkoutUrl]);

    if (!showHeader) return null;


    const goPortal = async (plan) => {
        setOverlay(true);
        try {
            api.post("stripe_go_to_billing_portal/", {}).then((res) => {
                setCheckoutUrl(res.data['url']);
            });
        } catch (error) {
            // console.error(error);
            setOverlay(false);
        }
    };


    return (
        <Box>
            <Flex
                bg={bgColor}
                color={linkColor}
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={borderColor}
                align={'center'}>
                <Flex
                    flex={{ md: 'auto' }}
                    ml={{ base: -2 }}
                    display={{ base: 'flex', md: 'none' }}>
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
                <Flex flex={{ base: 1 }} align='center' justify={{ base: 'center', md: 'start' }}>
                    <RouteLink to={'/'}><Image src='/assets/logo.png' alt='ProductListing.ai' height={30} display={{ sm: "none", md: "flex" }} /></RouteLink>
                    <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                        <Stack direction={'row'} spacing={4}>
                            {NAV_ITEMS.map((navItem) => (
                                <Box key={navItem.label}>
                                    <Link
                                        p={3}
                                        href={navItem.href ?? '#'}
                                        fontSize={'sm'}
                                        fontWeight={500}
                                        color={linkColor}
                                        borderRadius="10px"
                                        onClick={navItem.label == 'Logout' ? logoutUser : undefined }
                                        _hover={{
                                            textDecoration: 'none',
                                            backgroundColor: '#2C4058'

                                        }}>
                                        {navItem.label}
                                    </Link>
                                </Box>
                            ))}
                        </Stack>
                    </Flex>
                </Flex>
                <Stack
                    flex={{ base: 1, md: 0 }}
                    justify={'flex-end'}
                    direction={'row'}
                    spacing={6}>
                    <Button
                        as={'a'}
                        p={3}
                        fontSize={'sm'}
                        fontWeight={400}
                        variant='link'
                        color="#fff"
                        _hover={{
                            textDecoration: 'none',
                            backgroundColor: '#2C4058'
                        }}
                        href={'#'}>
                        {userData && userData.profile_data &&
                            <div>
                                {userData.profile_data.credits} {userData.profile_data.credits == 1 ? "Credit" : "Credits"}
                            </div>
                        }
                    </Button>
                    
                    {userData && userData.profile_data && userData.profile_data.plan == 'Lite' &&
                        <Button
                            fontSize={'sm'}
                            fontWeight={600}
                            color={'white'}
                            bg="#3182CE"
                            onClick={() => goPortal()}>
                            Upgrade
                        </Button>
                        
                    }
                    {userData && userData.profile_data && userData.profile_data.plan == 'Professional' &&
                        <Link href='https://docs.google.com/forms/d/e/1FAIpQLScj4oOH_bzIgzM7Kpd7weRVJGNzqq9yvvZJDScUhtLwu4Qeqw/viewform'>
                            <Button
                                fontSize={'sm'}
                                fontWeight={600}
                                color={'white'}
                                bg="#3182CE">
                                Upgrade
                            </Button>
                        </Link>
                    }
                    {userData && userData.profile_data && userData.profile_data.plan == 'Freemium' &&
                        <RouteLink to={'/account/upgrade/'}>
                            <Button
                                fontSize={'sm'}
                                fontWeight={600}
                                color={'white'}
                                bg="#3182CE">
                                Upgrade
                            </Button>
                        </RouteLink>
                    }
                </Stack>
            </Flex>
            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    );
};

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{ md: 'none' }}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'center'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}>
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}>
                    {label}
                </Text>
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};


const NAV_ITEMS = [
    {
        label: 'New',
        href: '/new-text/'

    },
    {
        label: 'Listings',
        href: '/listings/'
    },
    {
        label: 'Account',
        href: '/account/'
    },
    {
        label: 'Logout'
    },
];