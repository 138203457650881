// @TODO: make logout from django
import { createContext, useContext, useState, useEffect } from "react";
import { useLocation, Outlet, Navigate, useNavigate } from "react-router-dom";
import { useColorModeValue } from '@chakra-ui/react';
import { API_URL } from "../constants";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { useToast } from '@chakra-ui/react'

import jwt_decode from "jwt-decode";

const AuthContext = createContext(null);
const domain = (process.env.REACT_APP_DOMAIN_DEV) ?  process.env.REACT_APP_DOMAIN_DEV : process.env.REACT_APP_DOMAIN;
const protocol = (process.env.REACT_APP_DOMAIN_DEV) ? 'http://' : 'https://';

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const toast = useToast();

  const loginUser = async (username, password) => {
    const response = await fetch(protocol + domain + "/api/token/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username,
        password
      })
    })

    let data = '';
    try {
      data = await response.json();
    } catch (e) {
      console.log(e);
      // return false;
    }

    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
      navigate("/new-text/");
    } else {
      toast({
          position: 'top',
          title: 'Something went wrong',
          description: data.detail,
          status: 'error',
          duration: 9000,
          isClosable: true,
      });
      // console.log(data.detail);
    }
  };

  const signUpUser = (username, password, password2, first_name, last_name, email) => {
    
    const data = {
          'username': username,
          'password': password,
          'password2': password2,
          'first_name': first_name,
          'last_name': last_name,
          'email': email,
    };
    
    const result = axios.post(protocol + domain + '/api/signup/', data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
    return result;

    // const response = await fetch(protocol + domain + "/api/signup/", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify({
    //     username,
    //     password,
    //     password2,
    //     first_name,
    //     last_name,
    //     email
    //   })
    // });
    // if (response.status === 201) {
    //   loginUser(username, password);
    //   // navigate("/login/");
    // } else {
    //   console.log(response);
    //   toast({
    //     position: 'top',
    //     title: 'Something went wrong',
    //     description: 'asd',
    //     status: 'error',
    //     duration: 9000,
    //     isClosable: true,
    //   })
    // }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    navigate("/login/");
  };

  
  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    signUpUser,
    loginUser,
    logoutUser
  };


  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const RequireAuth = () => {
  const { user } = useAuth();
  const [res, setRes] = useOutletContext();

  const location = useLocation();
  const navigate = useNavigate();

  if (!user) {
    navigate("/login/");
    return (
      <Navigate
        to={{ pathname: "/login/", state: { from: location } }}
        replace
      />
    );
  }

  return <Outlet context={[res, setRes]} />;
};
