import { Box, Button, Flex, Heading, Input, Link, Text, Spinner } from '@chakra-ui/react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import React from 'react'
import { useState, useEffect } from 'react'
import LoginSideBox from '../sections/LoginSideBox'
import axios from "axios";
import { useToast } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";
const domain = (process.env.REACT_APP_DOMAIN_DEV) ?  process.env.REACT_APP_DOMAIN_DEV : process.env.REACT_APP_DOMAIN;
const protocol = (process.env.REACT_APP_DOMAIN_DEV) ? 'http://' : 'https://';

const ResetPassword = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const [Overlay, setOverlay] = useState(false); 

    const [showCode, setShowCode] = useState(false);
    const ResetSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
    });

    useEffect(() => {
        document.title = "Reset password - ProductListing.AI";
    }, []);

    const sendResetEmail = data => {
        setOverlay(true);

        // Send request to generate secret code
        if (data.email && data.code == '') {
            const link = protocol + domain + '/api/reset-password/?email='+data.email;
            axios.get(link)
            .then(function (response) {
                if (response.data.result == 1) {
                    // TODO: disable inputs
                    toast({
                        position: 'top',
                        title: 'Check your email',
                        description: 'We have just sent you an email with a code',
                        status: 'info',
                        duration: 9000,
                        isClosable: true,
                    });
                    setShowCode(true);
                    setOverlay(false);
                }
            })
            .catch(function (error) {
                if (error.response.status == 404) {
                    toast({
                        position: 'top',
                        title: 'Email not found',
                        description: 'Please, check your input',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        position: 'top',
                        title: 'Something went wrong',
                        description: 'Please, try again',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                setOverlay(false);
            })
        } else if (data.email && data.code != '') {
            // Send request to reset email

            const link = protocol + domain + '/api/reset-password/?email='+data.email+'&code='+data.code;

            axios.get(link)
            .then(function (response) {
                if (response.data.result == 1) {
                    navigate('/login/?password_changed=1')
                }
            })
            .catch(function (error) {
                toast({
                    position: 'top',
                    title: 'Something went wrong',
                    description: 'Please, try again',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .finally(function() {
                setOverlay(false);
            })
        }
    }
    return (
        <Box display="flex" flexDirection={{ sm: "column-reverse", md: "row" }} alignItems={{ sm: "center", md: "normal" }} justifyContent={{ sm: "start", md: "between" }} w="full" h="100vh">
            <Flex display={Overlay ? "flex" : "none"} alignItems="center" justifyContent="center" w="100%" h="100%" bgColor="#5A05052F" position="absolute" top="0" zIndex={2}>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='#5A0505'
                    size='xl'
                />
            </Flex>
            <Flex w={{ sm: "90%", md: "30%" }} display="flex" flexDirection="column" alignItems="center" justifyContent="center" position="relative">

                <Flex direction="column" align="center" >
                    <Heading mb={2}>Reset Password</Heading>
                    {!showCode ?
                        <Text fontSize="14px" textAlign="center">Please enter your email to reset your password.</Text>
                        : <Text fontSize="14px" textAlign="center">Please enter the code sent to your email.</Text>
                    }
                </Flex>
                <Formik
                    initialValues={{
                        email: '',
                        code: ''
                    }}
                    validationSchema={ResetSchema}
                    onSubmit={(values) => {
                        sendResetEmail(values);
                    }}>
                    {({ errors, touched, handleSubmit, handleChange, values }) => (
                        <Flex direction="column" align="flex-start" w="90%">
                            {!showCode && <Input placeholder='Email' mt={5} name="email" onChange={handleChange} value={values.email} />}
                            {errors.email && touched.email ? (<Text fontSize={14} color="#ff0000">{errors.email}</Text>) : null}
                            {showCode && <Input type="number" placeholder='Code' mt={5} name="code" onChange={handleChange} value={values.code} />}
                            {errors.code && touched.code ? (<Text fontSize={14} color="#ff0000">{errors.code}</Text>) : null}
                            <Button onClick={handleSubmit} colorScheme="blue" alignSelf="center" w="100%" mt={5}>{!showCode ? 'Reset' : 'Submit'}</Button>
                            <Link color="#5897f9" href='/login/' fontSize="14px" alignSelf="center" my={4}>Go back to Login page</Link>
                        </Flex>
                    )}
                </Formik>
            </Flex>
            <LoginSideBox />
        </Box>
    )
}

export default ResetPassword