import { Box, Button, Flex, Heading, Image, ListItem, Stack, Text, UnorderedList, List, Highlight } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'
import { AiOutlineReload } from "react-icons/ai"
import { MdContentCopy } from "react-icons/md"
import { useToast } from '@chakra-ui/react'
import copy from 'copy-to-clipboard';
import { AiFillFilePdf } from "react-icons/ai";
// import { FcDocument } from "react-icons/fc";
import { useState, useContext, useEffect, useRef } from 'react';
import TypewriterComponent from 'typewriter-effect';
import Typewriter from 'typewriter-effect';

import { useAuth } from "../../../../context/Auth";
import AuthContext from "../../../../context/Auth";
import { API_URL } from "../../../../constants"
import useAxios from "../../../../utils/useAxios"

import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from '../../../../utils/ComponentToPrint/ListingToPDF';

import { useOutletContext, useNavigate } from "react-router-dom";

const Listing = ({ context, setOverlay, onData }) => {
    // console.log(context['generated_production_benefits']);
    const loginUser = useContext(AuthContext);
    const user = useAuth();
    const api = useAxios();
    const toast = useToast();
    const navigate = useNavigate();

    // Convert to PDF
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [showPaywall, setShowPaywall] = useState(true);
    const [typewriterEffectTitle, setTypewriterEffectTitle] = useState(true);
    const [typewriterEffectSubtitles, settypewriterEffectSubtitles] = useState(true);
    const [typewriterEffectDescription, setTypewriterEffectDescription] = useState(true);
    const [typewriterEffectBenefits, setTypewriterEffectBenefits] = useState(true);

    const [userDataContext, setUserDataContext] = useOutletContext();
    const defaultProduct = {
        id: '',
        generated_title: '',
        generated_bullets: [],
        generated_production_description: [],
        generated_production_description_text: '',
        generated_production_benefits: [],
        generated_production_benefits_text: '',
        product_seo_keywords: [],
        product_bullet_points_emoji: ''
    };

    let [product, setProduct] = useState(defaultProduct);

    let listingPdf;
    const exportPDF = () => {
        listingPdf.save();
    }

    useEffect(() => {
        const userGroups = userDataContext.profile_data.groups;
        let contextData = context;
        document.title = contextData.product_title + ' - ProductListing.AI';

        let gp_description_text = '';
        let gp_bullets_text = '';
        let gp_benefits_text = '';
        let gp_description = contextData['generated_production_description'];
        let gp_benefits = contextData['generated_production_benefits'];
        
        if (contextData['generated_bullets'].length > 0) {
            for (let k in contextData['generated_bullets']) gp_bullets_text += '- ' + contextData['generated_bullets'][k] + '\n'
        }

        if (userGroups.indexOf('Lite') == -1 && userGroups.indexOf('Professional') == -1) {
            setShowPaywall(true);

            gp_description = [
                {
                    heading: "Enhance any Dish",
                    text: "A sprinkle of fleur de sel sea salt is a must-have for any culinary enthusiast. Its unique flavor offers a gourmet experience that truly elevates the taste of any dish. This delicate sea salt enhances the natural flavors of a meal, making it more enjoyable and memorable. With its subtle, yet distinct flavor, it is sure to bring an extra special touch to any culinary masterpiece. It is a great accompaniment for a variety of dishes, from fish and seafood to salads and desserts. With fleur de sel sea salt, you can easily transform ordinary meals into extraordinary ones."
                },
                {
                    heading: "Enhance any Dish",
                    text: "A sprinkle of fleur de sel sea salt is a must-have for any culinary enthusiast. Its unique flavor offers a gourmet experience that truly elevates the taste of any dish. This delicate sea salt enhances the natural flavors of a meal, making it more enjoyable and memorable. With its subtle, yet distinct flavor, it is sure to bring an extra special touch to any culinary masterpiece. It is a great accompaniment for a variety of dishes, from fish and seafood to salads and desserts. With fleur de sel sea salt, you can easily transform ordinary meals into extraordinary ones."
                },
                {
                    heading: "Enhance any Dish",
                    text: "A sprinkle of fleur de sel sea salt is a must-have for any culinary enthusiast. Its unique flavor offers a gourmet experience that truly elevates the taste of any dish. This delicate sea salt enhances the natural flavors of a meal, making it more enjoyable and memorable. With its subtle, yet distinct flavor, it is sure to bring an extra special touch to any culinary masterpiece. It is a great accompaniment for a variety of dishes, from fish and seafood to salads and desserts. With fleur de sel sea salt, you can easily transform ordinary meals into extraordinary ones."
                }
            ]
            gp_benefits = [
                'All-natural',
                'Hand-harvested',
                'Finest quality',
                'Add depth to dishes',
                'Gourmet finishing salt'
            ]

        } else {
            setShowPaywall(false);

            if (gp_description.length > 0) {

                // Set additional tick
                if (contextData.product_description_emoji == 'tick') {
                    gp_description = gp_description.map(function (element) {
                        if (element['heading'].indexOf('✔️') == -1) {
                            element['heading'] = '✔️ ' + element['heading'];
                        }
                        return element;
                    });
                }

                // Text for "copy to clipboard"
                for (let k in gp_description) gp_description_text += gp_description[k].heading + '\n' + gp_description[k].text + '\n\n';
            }

            // Text for "copy to clipboard"
            if (gp_benefits.length > 0) {
                for (let k in gp_benefits) gp_benefits_text += '- ' + gp_benefits[k] + '\n'
            }

            if (contextData.product_bullet_points_emoji == 'tick' && contextData['generated_bullets'].length > 0) {
                contextData['generated_bullets'] = contextData['generated_bullets'].map(function (element) {
                    if (element.indexOf('✔️') == -1) {
                        element = '✔️ ' + element;
                    }
                    return element;
                });
            }
        }

        setProduct({
            id: contextData['custom_id'],
            generated_title: contextData['generated_title'],
            generated_bullets: (contextData['generated_bullets'].length == 0) ? [] : contextData['generated_bullets'],
            generated_bullets_text: gp_bullets_text,
            generated_production_description: (gp_description.length == 0) ? [] : gp_description,
            generated_production_description_text: gp_description_text,
            generated_production_benefits: (contextData['generated_production_benefits'].length == 0) ? [] : gp_benefits,
            generated_production_benefits_text: gp_benefits_text,
            product_seo_keywords: (contextData['product_seo_keywords'].length == 0) ? [] : contextData['product_seo_keywords'],
            product_bullet_points_emoji: contextData.product_bullet_points_emoji
        })

        // if (product.generated_title) setTypewriterEffect({ ...typewriterEffect, title: true });
        // console.log(contextData);
        // console.log(product);

    }, [userDataContext, context]);
    
    useEffect(() => {
        onData(product);
    }, [product]);

    const regenerate_item = (tag, index) => {
        // console.log(tag);
        // console.log(index);
        setOverlay(true);
        const id = context['custom_id'];

        api.patch(API_URL + id, { 'updateAction': tag, 'index': index }).then((result) => {

            // console.log(result.data);
            if (result.data.error) {
                toast({
                    position: 'top',
                    title: 'Regeneration limit exceeded',
                    description: "You have used all 40 possible regenerations for one listing",
                    status: 'warning',
                    duration: 9000,
                    isClosable: true,
                });
                setOverlay(false);
            } else {
                // console.log(result);
                let dataUpdated = {
                    ...product,
                };
                if (tag == 'generated_bullets' || tag == 'product_seo_keywords' || tag == 'generated_production_description' || tag == 'generated_production_benefits') {
                    dataUpdated[tag] = JSON.parse(result['data'][tag]);
                    // console.log(dataUpdated[tag]);
                } else dataUpdated[tag] = result['data'][tag];

                setProduct(dataUpdated);
                setOverlay(false);
            }
        }).catch(function (error) {
            toast({
                position: 'top',
                title: 'Something went wrong',
                description: "Please, try again",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setOverlay(false);
        });
    }

    const copyText = (item, index) => {

        let text = product[item];

        if (index != undefined) text = text[index];

        copy(text, {
            debug: true,
            message: 'Press #{key} to copy',
        });
    }
    return (
        <Box w="100%">
            <Flex direction="row" align="center" mt="60px" mb="25px">
                <Image src='/assets/humburger.svg' mr={2} />
                <Heading>Your Product Listing</Heading>
            </Flex>
            <Stack spacing={10} pl={7}>
                <Stack spacing={3} w={{ sm: "100%", md: "90%" }}>
                    <Text fontSize="22px" fontWeight="bold">Product Title</Text>
                    <Box key="product">
                        <Flex direction="row" align="flex-start">
                            {typewriterEffectTitle ? <Text fontSize="30px" fontWeight="semibold" mb={5} borderWidth="1px" p="2" w="100%">
                                
                                <Typewriter component={'span'}
                                    onInit={(typewriter) => {
                                        typewriter.typeString(product.generated_title)
                                            .callFunction(() => {
                                                setTypewriterEffectTitle(false);
                                            })
                                            .start();
                                    }}
                                    options={{
                                        cursor: "",
                                        delay: 30
                                    }}
                                />
                            </Text> :
                                <Text fontSize="30px" fontWeight="semibold" mb={5} borderWidth="1px" p="2" w="100%">
                                    <Highlight query={product.product_seo_keywords} styles={{ color: '#18A9FB', fontWeight: "bold" }}>
                                        {product.generated_title}
                                    </Highlight>
                                </Text>
                            }
                            <Flex direction="column" align="center" justify="center">
                                <Button variant='link' _hover={{
                                    textDecoration: 'none',
                                }} onClick={() => regenerate_item('generated_title')} > <Icon as={AiOutlineReload} mb={3} boxSize={6} /> </Button>
                                <Button onClick={() => copyText('generated_title')} variant='link' _hover={{
                                    textDecoration: 'none',
                                }}>  <Icon as={MdContentCopy} boxSize={6} /> </Button>
                            </Flex>
                        </Flex>
                        <Text fontSize="22px" fontWeight="bold" mb={5}>Bullet Points</Text>
                        {product.generated_bullets?.map((bullet, index) =>
                            (
                                <Flex key={'bullet-list-flex-' + index} fontSize="14px" align="flex-start">
                                    <Box borderWidth="1px" p={2} px={4} mb={9} w="100%">
                                        {product.product_bullet_points_emoji == 'tick' &&
                                            <List>
                                                <ListItem>
                                                    {typewriterEffectSubtitles ?
                                                        <Typewriter component={'span'}
                                                            onInit={(typewriter) => {
                                                                typewriter.typeString(bullet)
                                                                    .callFunction(() => {
                                                                        settypewriterEffectSubtitles(false);
                                                                    })
                                                                    .start();
                                                            }}
                                                            options={{
                                                                cursor: "",
                                                                delay: 30
                                                            }}
                                                        /> :
                                                        <Text fontSize="14px">
                                                            <Highlight query={product.product_seo_keywords} styles={{ color: '#18A9FB', fontWeight: "bold" }}>
                                                                {bullet}
                                                            </Highlight>
                                                        </Text>
                                                    }
                                                </ListItem>
                                            </List>
                                        }

                                        {product.product_bullet_points_emoji != 'tick' &&
                                            <UnorderedList>
                                                <ListItem>
                                                    {typewriterEffectSubtitles ?
                                                        <Typewriter component={'span'}
                                                            onInit={(typewriter) => {
                                                                typewriter.typeString(bullet)
                                                                    .callFunction(() => {
                                                                        settypewriterEffectSubtitles(false);
                                                                    })
                                                                    .start();

                                                            }}
                                                            options={{
                                                                cursor: "",
                                                                delay: 30
                                                            }}
                                                        /> :
                                                        <Text fontSize="14px">
                                                            <Highlight query={product.product_seo_keywords} styles={{ color: '#18A9FB', fontWeight: "bold" }}>
                                                                {bullet}
                                                            </Highlight>
                                                        </Text>
                                                    }
                                                </ListItem>
                                            </UnorderedList>
                                        }
                                    </Box>
                                    <Flex key={'bullet-list-flex2-' + index} direction="column" align="center" justify="center">
                                        <Button key={'bullet-list-but1-' + index} variant='link' _hover={{
                                            textDecoration: 'none',
                                        }} > <Icon key={'bullet-list-ic-' + index} as={AiOutlineReload} mb={3} boxSize={5} onClick={() => regenerate_item('generated_bullets', (index + 1))} /> </Button>
                                        <Button onClick={() => copyText('generated_bullets', index)} variant='link' _hover={{
                                            textDecoration: 'none',
                                        }}>  <Icon as={MdContentCopy} boxSize={5} /> </Button>
                                    </Flex>
                                </Flex>
                            )
                        )}
                    </Box>
                    ))
                </Stack>
                <Flex position="relative" direction='column' align="flex-start" justifyContent="center">
                    <Text fontSize="22px" fontWeight="bold" mb={5}>Product Description</Text>
                    <Stack direction="row" ml={2} alignItems="flex-start" w={{ sm: "100%", md: "90%" }} filter={showPaywall && "blur(5px)"} userSelect={showPaywall && "none"} pointerEvents={showPaywall && "none"} position="relative">
                        <Box borderWidth="1px" p={2} px={2} w="100%">
                            {product.generated_production_description?.map((pd, index) => (
                                <Box mb={2} key={'generated_production_description-' + index}>
                                    <Flex direction="row" align="center">
                                        <Text fontWeight="bold" fontSize="17px">
                                            {!showPaywall ? <TypewriterComponent component={'span'}
                                                options={{
                                                    strings: pd.heading,
                                                    autoStart: true,
                                                    delay: 30,
                                                    cursor: ""
                                                }}
                                            />
                                                : pd.heading}

                                        </Text>
                                    </Flex>
                                    <Text>
                                        {typewriterEffectDescription && !showPaywall ?
                                            <Typewriter
                                                component={'span'}
                                                onInit={(typewriter) => {
                                                    typewriter.typeString(pd.text)
                                                        .callFunction(() => {
                                                            setTypewriterEffectDescription(false);
                                                        })
                                                        .start();

                                                }}
                                                options={{
                                                    cursor: "",
                                                    delay: 30
                                                }}
                                            /> :
                                            <Highlight query={product.product_seo_keywords} styles={{ color: '#18A9FB', fontWeight: "bold" }}>
                                                {pd.text}
                                            </Highlight>
                                        }
                                    </Text>
                                </Box>
                            ))}
                        </Box>
                        <Flex direction="column" align="center" justify="center">
                            <Button variant='link' _hover={{
                                textDecoration: 'none',
                            }} > <Icon as={AiOutlineReload} onClick={() => regenerate_item('generated_production_description')} mb={3} boxSize={5} /> </Button>
                            <Button onClick={() => copyText('generated_production_description_text')} variant='link' _hover={{
                                textDecoration: 'none',
                            }}>  <Icon as={MdContentCopy} boxSize={5} /> </Button>
                        </Flex>
                    </Stack>

                    <Flex display={!showPaywall ? "none" : "flex"} direction="column" align="center" alignSelf="center" w={{ sm: "250px", md: "380px" }} py={3} px={2} backgroundColor="#F4A023E0" color="#fff" position="absolute" >
                        <Text fontWeight="bold">Generate full Product Listings Includes</Text>
                        <UnorderedList>
                            <ListItem key='includeDescr'>Includes Description</ListItem>
                            <ListItem key='includeSeo'>Includes inclusion of SEO Keywords</ListItem>
                            <ListItem key='includeBenefits'>Includes Benefits</ListItem>
                            <ListItem key='includeExport'>Includes Exports to PDF</ListItem>
                        </UnorderedList>
                        <Button variant='link' color="#fff" mt={5} _hover={{
                            textDecoration: 'none',
                        }} onClick={() => navigate('/account/upgrade/')}>UPGRADE NOW</Button>
                    </Flex>
                </Flex>


                {Array.isArray(product.generated_production_benefits) && product.generated_production_benefits.length > 0 &&
                    <Flex position="relative" direction='column' align="flex-start" justifyContent="center">
                        <Text fontSize="22px" fontWeight="bold" mb={5}>Product Benefits</Text>
                        <Stack direction="row" ml={2} alignItems="flex-start" w={{ sm: "100%", md: "90%" }} filter={showPaywall && "blur(5px)"} userSelect={showPaywall && "none"} pointerEvents={showPaywall && "none"} position="relative">
                            <Box borderWidth="1px" p={2} px={2} w="100%">
                                {product.generated_production_benefits?.map((pd, index) => (
                                    <Box mb={2} key={'generated_production_benefits-' + index}>
                                        <Box fontWeight="">
                                            {typewriterEffectBenefits && !showPaywall ?
                                                <Typewriter
                                                    component={'span'}
                                                    onInit={(typewriter) => {
                                                        typewriter.typeString(pd)
                                                            .callFunction(() => {
                                                                setTypewriterEffectBenefits(false);
                                                            })
                                                            .start();

                                                    }}
                                                    options={{
                                                        cursor: "",
                                                        delay: 30
                                                    }}
                                                />
                                                :
                                                <Highlight query={product.product_seo_keywords} styles={{ color: '#18A9FB', fontWeight: "bold" }}>
                                                    {pd}
                                                </Highlight>
                                            }
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                            <Flex direction="column" align="center" justify="center">
                                <Button variant='link' _hover={{
                                    textDecoration: 'none',
                                }} > <Icon as={AiOutlineReload} onClick={() => regenerate_item('generated_production_benefits')} mb={3} boxSize={5} /> </Button>
                                <Button onClick={() => copyText('generated_production_benefits_text')} variant='link' _hover={{
                                    textDecoration: 'none',
                                }}>  <Icon as={MdContentCopy} boxSize={5} /> </Button>
                            </Flex>
                        </Stack>
                    </Flex>
                }
                <Stack direction={{ sm: 'column', md: 'row' }} justifyContent="center" alignItems={{ sm: 'center', md: 'normal' }} spacing={4} filter={showPaywall && "blur(2px)"} userSelect={showPaywall && "none"} pointerEvents={showPaywall && "none"}>
                    <ReactToPrint
                        trigger={() => <Button leftIcon={<AiFillFilePdf />} onClick={() => exportPDF()} colorScheme='brand' variant='solid' w="fit-content">
                            Export PDF
                        </Button>
                        }
                        content={() => componentRef.current}
                    />
                    <div
                        style={{
                            position: "absolute",
                            left: "-20000px",
                            top: '-1000px',
                        }}
                    >
                        <ComponentToPrint ref={componentRef} data={product} />
                    </div>
                    {/* <Button leftIcon={<FcDocument />} colorScheme='brand' variant='solid' w="fit-content">
                        Export Word
                    </Button> */}
                </Stack>
            </Stack>
        </Box>
    )
}

export default Listing