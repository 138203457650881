import React from "react";
import { MdDeleteForever } from "react-icons/md"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Icon
} from '@chakra-ui/react';

import { useToast } from '@chakra-ui/react'

import useAxios from "../../utils/useAxios"
import { API_URL } from "../../constants";


function ConfirmRemovalModal(context) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const api = useAxios();
    const toast = useToast();
    
    function deleteItem(onClose, context) {
        console.log(context);
        api.delete(API_URL + context.pk).then(() => {
            context.onDelete();
            onClose();
        })
        .catch(function (error) {
            toast({
                position: 'top',
                title: 'Something went wrong',
                description: 'Please refresh the page and try again',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        });
    };

    return (
        <>
            {/* <Button onClick={onOpen}><DeleteIcon /></Button> */}
            <Icon as={MdDeleteForever} mr={2} cursor="pointer" onClick={onOpen} />

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Do you want to delete this item?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        You will not be able to revert this action.
                    </ModalBody>

                    <ModalFooter>
                        <Button mr={3} colorScheme='red' onClick={() => deleteItem(onClose, context)}>Delete</Button>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
export default ConfirmRemovalModal;