// @TODO: change routes and folders to similar
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import { AuthProvider, RequireAuth } from "./context/Auth";
import Layout from "./components/layouts/Layout";
import HomePage from "./components/layouts/Home";
import Signup from "./components/layouts/Signup";
import LoginPage from "./components/layouts/Login";
import NotFoundPage from "./components/layouts/NotFound";
import UnauthorizedPage from "./components/layouts/Unauthorized";
import Listings from "./components/pages/Listings";
import AddText from "./components/pages/AddText/AddText";
import Account from "./components/pages/Account";
import ResetPassword from "./components/pages/ResetPassword";
import Upgrade from "./components/pages/Upgrade";


const App = () => {

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/login/" element={<LoginPage />} />
            <Route path="/signup/" element={<Signup />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/unauthorized/" element={<UnauthorizedPage />} />
            <Route path="/reset-password/" element={<ResetPassword />} />
            <Route path="/unauthorized/" element={<UnauthorizedPage />} />
            <Route element={<RequireAuth />}>
              <Route path="/account/" element={<Account />} />
              <Route path="/" element={<HomePage />} />
              <Route path="/account/upgrade/" element={<Upgrade />} />
              <Route path="/new-text/" element={<AddText generate={true} />} />
              <Route path="/listings/" element={<Listings />} />
              <Route path="/listings/:textId/" element={<AddText generate={false} />} />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;