import { Box, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { useLocation } from "react-router-dom";

const Footer = () => {
    const location = useLocation();

    const showHeader = (location.pathname == '/login/' || location.pathname == '/signup/' || location.pathname == '/reset-password/') ? false : true;
    
    if (!showHeader) return null;
    return (
        <Box w="100%" bgColor="#000" align="center" pt="4" pb="5" marginTop="auto">
            <Image src='/assets/footerLogo.svg' />
            <Text color="#FFF">Questions ? Contact us: <a
                    onMouseEnter={(e) => {
                        e.target.style.textDecoration = 'underline';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.textDecoration = 'none';
                    }} href="mailto:hello@productlisting.ai">hello@productlisting.ai</a>
            </Text>
        </Box>
    )
}

export default Footer