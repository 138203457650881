import { Link, Button, Flex, FormControl, FormLabel, Heading, Stack, Switch, Text, Spinner } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useState } from 'react'
// import axios from "axios";
// import { API_URL } from "../../constants" // @TODO: 
import useAxios from "../../utils/useAxios"
import { useToast } from '@chakra-ui/react'
import { useOutletContext, useLocation, useNavigate } from "react-router-dom";


const Upgrade = () => {

    const api = useAxios();
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const [userDataContext, setUserDataContext] = useOutletContext();
    const [ payAnnually, setPayAnnually ] = useState(true);
    const [ checkoutUrl, setCheckoutUrl ] = useState(null);
    const [ Overlay, setOverlay ] = useState(false);
    
    useEffect(() => {
        document.title = "Account Upgrade - ProductListing.AI";
    }, []);

    // console.log(props);
    const goPortal = async () => {
        setOverlay(true);
        try {
            api.post("stripe_go_to_billing_portal/", {}).then((res) => {
                setCheckoutUrl(res.data['url']);
            });
        } catch (error) {
            // console.error(error);
            setOverlay(false);
        }
    };

    useEffect(() => {
        if (location.pathname == '/account/upgrade/') {
    
            if (userDataContext.profile_data.billing == 'annually' && userDataContext.profile_data.plan == 'Professional') {
                setOverlay(true);
                window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScj4oOH_bzIgzM7Kpd7weRVJGNzqq9yvvZJDScUhtLwu4Qeqw/viewform';
                setOverlay(false);
            } else if (userDataContext.profile_data.plan == 'Lite' || userDataContext.profile_data.plan == 'Professional') {
                setOverlay(true);
                goPortal();
                setOverlay(false);
            }
        }

    }, [userDataContext]);


    // const showHeader = (location.pathname == '/login/' || location.pathname == '/signup/' || location.pathname == '/reset-password/') ? false : true;

    // @TODO: Get from endpoint
    const plansAndPrices = {
        'lite' : {
            'name' : 'Lite',
            'monthly' : 49,
            'annually' : 39
        },
        'professional' : {
            'name' : 'Professional',
            'monthly' : 99,
            'annually' : 75
        }
    }



    const changePayType = (annually) => {
        setPayAnnually(annually);
    }

    useEffect(() => {
        if (!checkoutUrl) return;
        window.location.href = checkoutUrl;
    }, [checkoutUrl]);

    const goPay = async (plan) => {
        setOverlay(true);
        const type = (payAnnually) ? 'annually' : 'monthly';
        try {
            const data = {
                plan: plan,
                type: type
            }
            api.post("stripe-create-checkout-session/", data).then((res) => {
                if (res.data[0]['url'].length > 0) {
                    setCheckoutUrl(res.data[0]['url']);
                } else {
                    toast({
                        position: 'top',
                        title: 'Something went wrong',
                        description: "Please, try again later",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                    setOverlay(false);
                }
            }).catch((error) => {
                toast({
                    position: 'top',
                    title: 'Something went wrong',
                    description: "Please, try again later",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
                setOverlay(false);
            });
        } catch (error) {
            console.error(error);
            setOverlay(false);
        }

    };

    return (
        <Flex direction="column" align="flex-start" mr={7} my="60px">
            <Flex display={Overlay ? "flex" : "none"} alignItems="center" justifyContent="center" w="100%" h="100%" bgColor="#5A05052F" position="absolute" top="0" zIndex={2}>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='#5A0505'
                    size='xl'
                />
            </Flex>
            <Heading ml="70px" mb="30px">Upgrade your Account</Heading>
            <Flex direction="column" align="center" w="100%" py={3} px={5} my={10} alignSelf="center">
                <FormControl display='flex' alignItems='center' w="fit-content" px={2} py={2} bgColor="#fff" borderRadius={5}>
                    <FormLabel htmlFor='email-alerts' mb='0'>
                        Pay Annually and Save!
                    </FormLabel>
                    <Switch id='email-alerts' colorScheme='brand' onChange={(e) => {
                        changePayType(e.target.checked);
                    }} defaultChecked />
                </FormControl>
                <Flex w="100%" flexDirection={{ sm: "column", md: "row" }} justifyContent="center" align={{ sm: "center", md: "normal" }}>
                    <Flex direction="column" align="center" bgColor="#fff" borderWidth={1} py={4} px={3} my={3} mr={{ md: 10 }} w={{ sm: "90%", md: "290px" }} borderRadius="10px">
                        <Stack textAlign="center" spacing={1} mb={5}>
                            <Text fontSize="20px" fontWeight="bold">{plansAndPrices.lite.name}</Text>
                            <Text fontSize="14px" fontWeight="medium">For Beginners</Text>
                        </Stack>
                        <Stack textAlign="center" spacing={1} mb="60px">
                            <Text fontSize="20px" fontWeight="bold">10 Listings</Text>
                            <Text fontSize="14px" fontWeight="medium">SEO Optimization</Text>
                            <Text fontSize="14px" fontWeight="medium">Title Content</Text>
                            <Text fontSize="14px" fontWeight="medium">Bullet Points Content</Text>
                            <Text fontSize="14px" fontWeight="medium">Description Content</Text>
                            <Text fontSize="14px" fontWeight="medium">Export Listing</Text>
                            <Text fontSize="14px" fontWeight="medium">Unlimited Revisions</Text>
                            <Text fontSize="14px" fontWeight="medium">English Only</Text>
                            <Text fontSize="14px" fontWeight="medium" height={'21px'}> </Text>
                            <Text fontSize="14px" fontWeight="medium" height={'21px'}> </Text>
                        </Stack>
                        <Stack textAlign="center" mb={5}>
                            <Flex>
                                <Text fontSize={18} fontWeight="bold" color="#C4C4C4">$</Text>
                                <Text fontSize={40} fontWeight="bold" lineHeight="10">{payAnnually ? plansAndPrices.lite.annually : plansAndPrices.lite.monthly}</Text>
                                <Text fontSize={16} fontWeight="semibold" color="#C4C4C4" alignSelf="flex-end" lineHeight="10">/month</Text>
                            </Flex>
                            <Text color="#C4C4C4" fontSize="13px" >{payAnnually ? 'Billed Annually' : 'Billed Monthly'}</Text>

                        </Stack>
                        <Button colorScheme='orange' variant='outline' height="30px" width="150px" borderRadius={3} onClick={() => goPay('lite')}>
                            Start Now
                        </Button>
                    </Flex>
                    <Flex direction="column" align="center" bgColor="#fff" borderWidth={1} borderColor="#F96F02" py={4} px={3} my={3} mr={{ md: 10 }} w={{ sm: "90%", md: "290px" }} borderRadius="10px" position="relative">
                        <Text fontSize="11px" color="#fff" bgColor="#F96F02" p="0.5" borderBottomLeftRadius="3" borderTopRightRadius="10px" position="absolute" top="0" right="-0.1">MOST POPULAR</Text>
                        <Stack textAlign="center" spacing={1} mb={5}>
                            <Text fontSize="20px" fontWeight="bold">{plansAndPrices.professional.name}</Text>
                            <Text fontSize="14px" fontWeight="medium">For Specialists</Text>
                        </Stack>
                        <Stack textAlign="center" spacing={1} mb="60px">
                            <Text fontSize="20px" fontWeight="bold">50 Listings</Text>
                            <Text fontSize="14px" fontWeight="medium">SEO Optimization</Text>
                            <Text fontSize="14px" fontWeight="medium">Title Content</Text>
                            <Text fontSize="14px" fontWeight="medium">Bullet Points Content</Text>
                            <Text fontSize="14px" fontWeight="medium">Description Content</Text>
                            <Text fontSize="14px" fontWeight="medium">Export Listing</Text>
                            <Text fontSize="14px" fontWeight="medium">Unlimited Revisions</Text>
                            <Text fontSize="14px" fontWeight="medium">English Only</Text>
                            <Text fontSize="14px" fontWeight="medium" height={'21px'}> </Text>
                            <Text fontSize="14px" fontWeight="medium" height={'21px'}> </Text>
                        </Stack>
                        <Stack textAlign="center" mb={5}>
                            <Flex>
                                <Text fontSize={18} fontWeight="bold" color="#C4C4C4">$</Text>
                                <Text fontSize={40} fontWeight="bold" lineHeight="10">{payAnnually ? plansAndPrices.professional.annually : plansAndPrices.professional.monthly}</Text>
                                <Text fontSize={16} fontWeight="semibold" color="#C4C4C4" alignSelf="flex-end" lineHeight="10">/month</Text>
                            </Flex>
                            <Text color="#C4C4C4" fontSize="13px" >{payAnnually ? 'Billed Annually' : 'Billed Monthly'}</Text>

                        </Stack>
                        <Button colorScheme='orange' variant='outline' height="30px" width="150px" borderRadius={3}  onClick={() => goPay('professional')}>
                            Start Now
                        </Button>
                    </Flex>
                    <Flex direction="column" align="center" bgColor="#fff" borderWidth={1} py={4} px={3} my={3} mr={{ md: 10 }} w={{ sm: "90%", md: "290px" }} borderRadius="10px">
                        <Stack textAlign="center" spacing={1} mb={5}>
                            <Text fontSize="20px" fontWeight="bold">Custom</Text>
                            <Text fontSize="14px" fontWeight="medium">For Teams</Text>
                        </Stack>
                        <Stack textAlign="center" spacing={1} mb="60px">
                            <Text fontSize="20px" fontWeight="bold">Custom Listings</Text>
                            <Text fontSize="14px" fontWeight="medium">SEO Optimization</Text>
                            <Text fontSize="14px" fontWeight="medium">Title Content</Text>
                            <Text fontSize="14px" fontWeight="medium">Bullet Points Content</Text>
                            <Text fontSize="14px" fontWeight="medium">Description Content</Text>
                            <Text fontSize="14px" fontWeight="medium">Export Listing</Text>
                            <Text fontSize="14px" fontWeight="medium">Unlimited Revisions</Text>
                            <Text fontSize="14px" fontWeight="medium">Whitelabel Export</Text>
                            <Text fontSize="14px" fontWeight="medium">Bulk Generation</Text>
                            <Text fontSize="14px" fontWeight="medium">5 Languages</Text>
                        </Stack>
                        <Stack textAlign="center" mb={5}>
                            <Flex>
                                {/* <Text fontSize={18} fontWeight="bold" color="#C4C4C4">$</Text> */}
                                <Text fontSize={40} fontWeight="bold" lineHeight="10">Custom</Text>
                                {/* <Text fontSize={16} fontWeight="semibold" color="#C4C4C4" alignSelf="flex-end" lineHeight="10">/month</Text> */}
                            </Flex>
                            <Text color="#C4C4C4" fontSize="13px" height={'21px'} > </Text>

                        </Stack>
                        <Link href='https://docs.google.com/forms/d/e/1FAIpQLScj4oOH_bzIgzM7Kpd7weRVJGNzqq9yvvZJDScUhtLwu4Qeqw/viewform' isExternal>
                            <Button colorScheme='orange' variant='outline' height="30px" width="150px" borderRadius={3}>
                                Start Now
                            </Button>
                        </Link>
                    </Flex>
                </Flex>
            </Flex>
            {/* <Text fontSize={18} fontWeight="bold" alignSelf="center">Downgrade to Free</Text> */}
        </Flex>
    )
}

export default Upgrade