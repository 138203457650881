import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Text,
    Input,
    Stack,
    Box,
    Link,
    Button,
    Center,
    Heading,
    Spinner,
    Flex
} from '@chakra-ui/react'
import { FcGoogle } from 'react-icons/fc';
import LoginSideBox from '../sections/LoginSideBox';
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/Auth";
import { useAuth } from "../../context/Auth";
import { useToast } from '@chakra-ui/react'


function Signup() {
    const { user } = useAuth();
    const { signUpUser } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (user != null) {
            navigate("/listings/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const toast = useToast();
    
    useEffect(() => {
        document.title = "Sign up - ProductListing.AI";
    }, []);

    const [Overlay, setOverlay] = useState(false);

    const SignupSchema = Yup.object().shape({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('required'),
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().required("required"),
    });

    const signUp = values => {
        
        // alert(JSON.stringify(values, null, 2))
        signUpUser(values['email'], values['password'], values['password'], values['first_name'], values['last_name'], values['email'])
        .then(response => {
            if (response.status == 400) {
                // error
                let errorText = '';
                for (let errorType in response.data) {
                    console.log(response.data[errorType]);
                    errorText += response.data[errorType].join('\n');
                }
                toast({
                    position: 'top',
                    title: 'Something went wrong',
                    description: errorText,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            } else if (response.status == 201) {
                toast({
                    position: 'top',
                    title: 'Check your email',
                    description: 'We have just sent you an email with a confirmation link',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                toast({
                    position: 'top',
                    title: 'Something went wrong',
                    description: 'Please, try again',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
            setOverlay(false);
            // console.log(data);
        })
        .catch(error => {
            toast({
                position: 'top',
                title: 'Something went wrong',
                description: 'Please, try again',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setOverlay(false);
            // console.log(error);
        })
    }
    const formStyles = {
        width: '100%',
    };

    return (
        <Box display="flex" flexDirection={{ sm: "column-reverse", md: "row" }} alignItems={{ sm: "center", md: "normal" }} justifyContent={{ sm: "start", md: "between" }} w="full" minH="100vh">
            <Box w={{ sm: "90%", md: "30%" }} display="flex" flexDirection="column" alignItems="center" justifyContent="center" position="relative">
                <Flex display={Overlay ? "flex" : "none"} alignItems="center" justifyContent="center" w="100%" h="100%" bgColor="#5A05052F" position="absolute" top="0" zIndex={2}>
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='#5A0505'
                        size='xl'
                    />
                </Flex>
                <Box w="90%" display="flex" flexDirection="column" mx={10}>
                    <Heading mb={2}>Sign up</Heading>
                    <Box display='flex' flexDirection="row">
                        <Text mr={2}>or </Text>
                        <Link color='#5897f9' href='/login/'>sign in to your account</Link>
                    </Box>
                </Box>
                <Formik
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        email: '',
                        password: '',
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(values) => {
                        signUp(values);
                    }}>
                    {({ values, errors, touched, handleChange, handleSubmit }) => (
                        <Box my={8} display="flex" flexDirection="column" alignItems="start" w="90%">
                            <form width="100%" style={formStyles} onKeyDown={event => {
                                if (event.key === "Enter") {
                                    handleSubmit();
                                }
                            }}>
                                <Box w="100%">
                                    <Stack spacing={4} w="100%" >
                                        <Input placeholder='First name' size='md' className='border-[1px] px-2 py-1' name='first_name' onChange={handleChange} value={values.first_name} />
                                        {errors.first_name && touched.first_name ? (<Text fontSize={14} color="#ff0000">{errors.first_name}</Text>) : null}
                                        <Input placeholder='Last name' size='md' className='border-[1px] px-2 py-1' name='last_name' onChange={handleChange} value={values.last_name} />
                                        {errors.last_name && touched.last_name ? (<Text fontSize={14} color="#ff0000">{errors.last_name}</Text>) : null}
                                        <Input placeholder='Email' size='md' className='border-[1px] px-2 py-1' name='email' onChange={handleChange} value={values.email} />
                                        {errors.email && touched.email ? (<Text fontSize={14} color="#ff0000">{errors.email}</Text>) : null}
                                        <Input placeholder='Password' type="password" size='md' className='border-[1px] px-2 py-1' name='password' onChange={handleChange} value={values.password} />
                                        {errors.password && touched.password ? (<Text fontSize={14} color="#ff0000">{errors.password}</Text>) : null}
                                    </Stack>

                                </Box>
                                {/* <Text fontSize={13} my={3} color="#bbb">This page is protected by reCAPTCHA, and subject to the Google
                                    <Link color='#5897f9' href='#' ml={1}>
                                        Privacy Policy
                                    </Link> and
                                    <Link color='#5897f9' href='#' ml={1}>
                                        Terms of service.
                                    </Link>
                                </Text> */}
                                {/* <Checkbox defaultChecked>I agree to the Dropbox Terms</Checkbox> */}
                                <Stack spacing={5} mt={5} w="100%" alignItems={{ sm: "center", md: "flex-start" }}>
                                    <Button onClick={handleSubmit} colorScheme='blue' w={{ sm: "216.33px", md: "90%" }}>Sign up</Button>
                                    {/* <Button
                                        w={{ sm: "fit-content", md: "90%" }}
                                        maxW={'lg'}
                                        variant={'outline'}
                                        leftIcon={<FcGoogle />}
                                        className="border-[1px] py-1">
                                        <Center w={{ md: 300 }}>
                                            <Text>Continue with Google</Text>
                                        </Center>
                                    </Button> */}
                                </Stack>
                            </form>
                        </Box>
                    )}
                </Formik>
            </Box >
            <LoginSideBox />
        </Box >
    ); 
}

export default Signup;
