import { Box } from '@chakra-ui/react'
import Welcome from './Welcome';
import Listing from './Listing';

const PageContent = ({ generate, setOverlay, context, onData }) => {

    return (
        <Box w={{ sm: "100%", md: "60%" }} px={10} mb="50px">
            {generate ? <Welcome /> : <Listing context={context} setOverlay={setOverlay} onData={onData} />}
        </Box >
    )
}

export default PageContent