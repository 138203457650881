import { Box, Button, Flex, Heading, Input, InputGroup, InputLeftElement, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, Spinner } from '@chakra-ui/react'
// import { MdDeleteForever } from "react-icons/md"
// import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai"
import { FiEdit2, FiSearch } from "react-icons/fi"
import { Icon } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import ConfirmRemovalModal from "../sections/ConfirmRemovalModal"
//"./ConfirmRemovalModal";

import { API_URL } from "../../constants"
import useAxios from "../../utils/useAxios"
import { Link, useLocation, useNavigate } from "react-router-dom";

const Listings = () => {
    const [ Overlay, setOverlay ] = useState(false);
    const api = useAxios();
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
        document.title = "My listings - ProductListing.AI";
    }, []);
    
    const [tableData, setTableData] = useState({'items': [], 'total': 0});
    const [searchText, setSearchText] = useState("");
    const [orderBy, setOrderBy] = useState('pk');
    const [orderDirection, setOrderDirection] = useState('desc');
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const [indexOfLastRow, setindexOfLastRow] = useState(currentPage * rowsPerPage);
    const [indexOfFirstRow, setindexOfFirstRow] = useState(indexOfLastRow - rowsPerPage);

    const updateItems = () => {
        const link = API_URL+'?page='+currentPage+'&order_by='+orderBy+'&order_direction='+orderDirection+'&search='+searchText;
        api.get(link).then((response) => {
            // console.log(response.data);
            const data = response.data;
            const items = data.items.map(item => {
                return {
                    'name': item['product_title'],
                    'brand': item['product_brand'],
                    'custom_id': item['custom_id']
                }
            });
            setTableData({
                'items': items,
                'total': data.total,
                'page': data.page,
                'totalPages': data.total_pages
            });
            setindexOfFirstRow(data.items_from);
            setindexOfLastRow(data.items_to);

            // console.log(response.data.total);
        });
    }

    useEffect(() => {
        try {
            updateItems();
        } catch {
            // setRes("Something went wrong");
        } finally {
            // setOverlay(false);
        }
    }, [location.path, currentPage, orderBy, orderDirection, searchText])

    const paginate = (direction) => {
        const pageNumber = tableData.page;
        if (direction === "back") {
            if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
            }
        } else if (direction === "forward") {
            if (currentPage <= pageNumber) {
                setCurrentPage(currentPage + 1);

            }
        }
    }
    
    const sortTable = (field) => {
        if (orderBy == field) {
            const direction = (orderDirection == 'desc') ? 'asc' : 'desc';
            setOrderDirection(direction);

        } else {
            if (field == 'pk') setOrderDirection('desc');
            else setOrderDirection('asc');
            setOrderBy(field);
        }
        // setOrderDirection();
    }

    const selectRow = (id) => {
        const selectedRow = document.getElementById(id);
        selectedRow.classList.toggle('selected');
    }
    // const selectTable = () => {
    //     const selectedRow = document.getElementById('tableBody');
    //     selectedRow.classList.toggle('selected');
    // }


    return (
        <TableContainer mx={{ md: 10 }}>
            <Flex display={Overlay ? "flex" : "none"} alignItems="center" justifyContent="center" w="100%" h="100%" bgColor="#5A05052F" position="absolute" top="0" zIndex={2}>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='#5A0505'
                    size='xl'
                />
            </Flex>
            <Flex direction={{ sm: "column", md: "row" }} justifyContent={{ md: "space-between" }} align="center" px="30px" my="50px">
                <Heading mb={3}>Listings</Heading>
                <Stack spacing={4} w={{ sm: "100%", md: "fit-content" }}>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<FiSearch color='gray.300' />}
                        />
                        <Input type="text" w={{ sm: "100%", md: "300px" }} placeholder="Search" onChange={(e) => setSearchText(e.target.value)} /> 
                        
                    </InputGroup>
                </Stack>
            </Flex>
            <Table variant='simple'>
                <Thead bgColor="#F5F9FB">
                    <Tr>
                        <Th cursor="pointer" _hover={{ color: '#F96F02' }} onClick={() => sortTable('product_title')}>
                            Name
                        </Th>
                        <Th cursor="pointer" _hover={{ color: '#F96F02' }} onClick={() => sortTable('product_brand')}>
                            Brand
                        </Th>
                        <Th cursor="pointer" _hover={{ color: '#F96F02' }} onClick={() => sortTable('pk')}>
                            ID
                        </Th>
                        <Th w="50px"></Th>
                    </Tr>
                </Thead>
                <Tbody id='tableBody'>
                    {tableData.items.map(data => (
                        <Tr key={data.custom_id} id={data.custom_id}>
                            <Td cursor="pointer" onClick={() => navigate('/listings/'+data.custom_id+'/')}>{data.name}</Td> 
                            <Td>{data.brand}</Td>
                            <Td>{data.custom_id}</Td>
                            <Td>
                                <ConfirmRemovalModal pk={data.custom_id} onDelete={updateItems} />
                                <Link to={'/listings/' + data.custom_id + '/'}><Icon as={FiEdit2} cursor="pointer" mr={2} /></Link>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Box display="flex" flexDirection={{ sm: "column", md: "row" }} alignItems="center" justifyContent="space-between" px="20px" py="10px" w="100%">
                <Text fontSize={15} color="" my={3}>Showing {indexOfFirstRow} to {indexOfLastRow} of {tableData.total} results</Text>
                <Flex direction="row" align="center">
                    {currentPage > 1 && 
                        <Button variant='outline' mr={3} onClick={() => paginate("back")}>Previous</Button>
                    }
                    {currentPage != tableData.totalPages && 
                        <Button variant='outline' onClick={() => paginate("forward")}>Next</Button>
                    }
                </Flex>
            </Box>
        </TableContainer>
    )
}

export default Listings