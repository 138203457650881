import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useContext } from "react";
import AuthContext from "../context/Auth";
import { useAuth } from "../context/Auth";


const domain = (process.env.REACT_APP_DOMAIN_DEV) ? process.env.REACT_APP_DOMAIN_DEV : process.env.REACT_APP_DOMAIN;
const protocol = (process.env.REACT_APP_DOMAIN_DEV) ? 'http://' : 'https://';
const baseURL = protocol + domain + "/api";

const useAxios = () => {
  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);
  const { user, logoutUser } = useAuth();

  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${authTokens?.access}` }
  });

  axiosInstance.interceptors.request.use(async req => {
    const user = jwt_decode(authTokens.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    const response = await axios.post(`${baseURL}/token/refresh/`, {
      refresh: authTokens.refresh
    }).catch(function(error) {
      // console.log(error);
      logoutUser();
    });

    localStorage.setItem("authTokens", JSON.stringify(response.data));

    setAuthTokens(response.data);
    setUser(jwt_decode(response.data.access));

    req.headers.Authorization = `Bearer ${response.data.access}`;
    return req;
  });

  return axiosInstance;
};

export default useAxios;