import { ChakraProvider, extendTheme, Box } from '@chakra-ui/react'
import { Outlet } from "react-router-dom";
import Header from '../sections/Header'
import Footer from '../sections/Footer'
import { useState, useEffect } from 'react';
import { useAuth } from "../../context/Auth";
import useAxios from "../../utils/useAxios"
import { useLocation } from "react-router-dom";

// import { productListingTheme } from '../../themes/productlisting/index'
export const theme = extendTheme({
    colors: {
      brand: {
        50: '#F96F02',
        500: '#F96F02',
        900: '#F96F02',
      }
    },
    breakpoints: {
      sm: '0px',
      md: '970px',
      lg: '960px',
      xl: '1200px',
      '2xl': '1536px',
    }
    
  })



export default function Layout() {
  const api = useAxios();
  const location = useLocation();
  const { user } = useAuth();

  
  const [userData, setUserData] = useState({
      'profile_data': {
          'credits': '0',
          'groups': ''
      }
  });
  // const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/user-data/");
        setUserData(response.data);
      } catch(e) {
        setUserData("Something went wrong");
      }
    };
    if (user != null) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [location.pathname]);

  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" display="flex" flexDirection="column">
        <Header props={userData} />
        <Outlet context={[userData, setUserData]} />
        <Footer />
      </Box>
    </ChakraProvider>
  )
};

// export default Layout;