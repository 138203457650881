import React from 'react';
import './listingPDF.css';

export class ComponentToPrint extends React.PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data });
        }
        // console.log(this.state.data);
    }
    render() {
        return (
            <div className='productDescription'>
                <h1>Your Product Listing</h1>
                <h2>Product Title</h2>
                <p>{this.state.data && this.state.data.generated_title}</p>

                {this.state.data['generated_bullets']?.length > 0 &&
                    <div>
                        <h2>Bullet Points</h2>
                        {this.state.data['generated_bullets']?.map((bullet, index) =>
                            (
                                <div key={index} className='bullet'>{bullet}</div>
                            )
                        )}
                    </div>
                }

                {this.state.data['generated_production_description']?.length > 0 &&
                    <div>
                        <h2>Product Description</h2>
                        <ul>
                        {this.state.data['generated_production_description']?.map((descrItem, index) =>
                            <li key={index}><b>{descrItem['heading']}</b><br />{descrItem['text']}</li>
                        )}
                        </ul>
                    </div>
                }

                {this.state.data['generated_production_benefits']?.length > 0 &&
                    <div>
                        <h2>Product Benefits</h2>
                        {this.state.data['generated_production_benefits']?.map((benefit, index) =>
                            <p key={index}>-{benefit}</p>
                        )}
                    </div>
                }
                <div className="copyright"><a href="https://productlisting.ai/">productlisting.ai</a></div>
            </div>
        );
    }
}