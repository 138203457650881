// import { InfoOutlineIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, Tooltip } from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons';
import { Form, Formik } from 'formik'
import * as Yup from 'yup';
import React from 'react'
import { useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { useAuth } from "../../context/Auth";
import { Link as RouteLink, useOutletContext, useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../utils/useAxios"
// import { API_URL } from "../../../constants"

const Account = () => {
    const location = useLocation();
    const api = useAxios();
    const navigate = useNavigate();
    
    const [Overlay, setOverlay] = useState(false); 
    const [checkoutUrl, setCheckoutUrl] = useState(null);
    const [userDataOutlet, setuserDataOutlet] = useOutletContext();
    const [userDataContext, setUserDataContext] = useState({
        'email': '',
        'first_name': '',
        'last_name': '',
        'profile_data': {
            'credits': 2,
            'credits_used_percents': 0,
            'plan' : '',
            'subscription_active_till': '',
            'billing': '',
            'stripe_customer_id': '',

        }
    });

    useEffect(() => {
        setUserDataContext(userDataOutlet);
    }, [userDataOutlet]);
    
    useEffect(() => {
        document.title = "My account - ProductListing.AI";
    }, []);

    const toast = useToast();
    const toastSuccessId = 'payment-toast-success';
    const toastCanceledId = 'payment-toast-canceled';
    const { user } = useAuth();
    const [ userData, setUserData ] = useState({
        email: '',
        first_name: '',
        last_name: ''
    });

    const goPortal = async (plan) => {
        setOverlay(true);
        try {
            api.post("stripe_go_to_billing_portal/", {}).then((res) => {
                setCheckoutUrl(res.data['url']);
            });
        } catch (error) {
            toast({
                position: 'top',
                title: 'Something wen wrong',
                description: "Please refresh the page and try again",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            setOverlay(false);
        }

    };

    useEffect(() => {
        if (!checkoutUrl) return;
        // console.log(checkoutUrl);
        window.location.href = checkoutUrl;
    }, [checkoutUrl]);

    useEffect(() => {
        setUserData({
            email: (userDataContext.email) ? userDataContext.email : '', // user.email
            first_name: (userDataContext.first_name) ? userDataContext.first_name : '', //user.first_name
            last_name: (userDataContext.last_name) ? userDataContext.last_name : '' // user.last_name
        })
    }, [userDataContext]);

    const query = new URLSearchParams(window.location.search);

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout

        if (query.get("payment") == 'success' && !toast.isActive(toastSuccessId)) {
            toast({
                toastSuccessId,
                position: 'top',
                title: 'Payment succefull',
                description: "You can use your Credits now",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        }

        if (query.get("payment") == 'canceled' && !toast.isActive(toastCanceledId)) {
            toast({
                toastCanceledId,
                position: 'top',
                title: 'Payment canceled',
                description: "Checkout when you're ready",
                status: 'info',
                duration: 9000,
                isClosable: true,
            })
        }
        if (query.get("email-confirmation") == 'exists') {
            toast({
                toastCanceledId,
                position: 'top',
                title: 'Email changing confirmation failed',
                description: "User with specified email address already exists",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
        if (query.get("email-confirmation") == 'error') {
            toast({
                toastCanceledId,
                position: 'top',
                title: 'Email changing confirmation failed',
                description: "Please, try again",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
        if (query.get("email-confirmation") == 'confirmed') {
            toast({
                toastCanceledId,
                position: 'top',
                title: 'Email changed',
                description: "",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [location.pathname]);

    const updateUser = async (formData) => {
        // console.log(formData);
        setOverlay(true);
        try {
            const updateResult = await api.patch('user-update/', formData)
            .then(function (result) {
                if (result.data.change_email) {
                    toast({
                        toastCanceledId,
                        position: 'top',
                        title: 'Please, check your email',
                        description: "We sent you a link to change your mail",
                        status: 'info',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            })
            .catch(function (error) {
                toast({
                    toastSuccessId,
                    position: 'top',
                    title: 'Something went wrong',
                    description: 'Please refresh the page and try again',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
                if (error.response) {
                    // Request made and server responded
                    //   console.log(error.response.data);
                    //   console.log(error.response.status);
                    //   console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    //   console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    //   console.log('Error', error.message);
                }
            });
        } catch (error) {
            toast({
                toastSuccessId,
                position: 'top',
                title: 'Something went wrong',
                description: error,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setOverlay(false);
        }
    }
    
    const LoginSchema = Yup.object().shape({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
    });

    return (
        <Box display="flex" flexDirection="column" alignItems="center" my="60px" pb="40px">
            <Heading alignSelf="flex-start" ml="80px" mb="30px">Your Account</Heading>
            <Stack display="flex" flexDirection="column" align="center" w={{ sm: "90%", md: "65%" }}>
                <Stack textAlign="center" mb={4}>
                    <Text fontSize="25px" fontWeight="bold" >Billing</Text>
                    <Text fontSize="18px" color="#586A7E" fontWeight="semibold">Available credits</Text>
                </Stack>
                <Flex flexDirection={{ sm: "column", md: "row" }} align="center" justify="center" w="100%" >
                    <Flex direction="column" justify="center" align={{ sm: "center", md: "normal" }} borderWidth="1px" borderRadius="7px" p={7} mr={{ md: 7 }} mb={{ sm: 4, md: 0 }} w="100%">
                        <Flex direction="row" align="center">
                            <Text fontSize="14px" fontWeight="bold" color="#586A7E" mr={2}>Total credits</Text>
                            {/* <InfoOutlineIcon color="#ddd" /> */}
                            <Tooltip hasArrow placement='top-start' openDelay={300} label='This is the amount of available credits. The amount is updated every month. Unused credits available for use in future months.' fontSize='md'>
                                <Box><InfoIcon mx={1} boxSize={3} alignSelf="center" /> </Box>
                            </Tooltip>
                        </Flex>
                        {userDataContext.profile_data && 
                            <Text fontSize="28px" fontWeight="bold">{userDataContext.profile_data.credits}</Text>
                        }
                        {userDataContext.profile_data && 
                            <Text fontSize="12px" color="#586A7E">{userDataContext.profile_data.credits_used_percents}% of plan credits used</Text>
                        }
                    </Flex>
                    {/* <Flex direction="column" justify="center" align={{ sm: "center", md: "normal" }} borderWidth="1px" borderRadius="7px" p={7} mb={{ sm: 4, md: 0 }} w="100%">
                        <Flex direction="row" align="center">
                            <Text fontSize="14px" fontWeight="bold" color="#586A7E" mr={2}>Plan credits</Text>
                            <InfoOutlineIcon color="#ddd" />
                        </Flex>
                        <Text fontSize="28px" fontWeight="bold">50,000</Text>
                        <Text fontSize="12px" color="#586A7E">0% of plan credits used</Text>
                    </Flex> */}
                </Flex>
                <Flex direction="column" align={{ sm: "center", md: "start" }} p={4} pt={10} w="100%" borderWidth="1px" borderRadius="7px" >
                    <Flex direction="column" ml={5} >
                        <Text fontSize={16} fontWeight="medium" mb={3}>Subscription</Text>
                        <Flex direction="row" align="center">
                            {userDataContext.profile_data && 
                                <Text mr={2} fontSize="18px" fontWeight="semibold">{userDataContext.profile_data.plan}</Text>
                            }
                            {userDataContext.profile_data && userDataContext.profile_data.subscription_active &&
                                <Button color='#50D18E' borderColor="#50D18E" variant='outline' fontSize="14px" height="25px" width="60px">ACTIVE</Button>
                            }
                            {userDataContext.profile_data && !userDataContext.profile_data.subscription_active && userDataContext.profile_data.plan != 'Freemium' && 
                                <Button color='#d6353d' borderColor="#d6353d" variant='outline' fontSize="14px" height="25px" width="80px">INACTIVE</Button>
                            }
                        </Flex>
                        {userDataContext.profile_data && 
                            <Text fontSize={16} color="#586A7E">{userDataContext.profile_data.credits} credits</Text>
                        }
                        {userDataContext.profile_data && userDataContext.profile_data.plan != 'Freemium' && 
                            <Box>
                                <Text fontSize={16} color="#586A7E">Billing cycle: {userDataContext.profile_data.billing}</Text>
                                {userDataContext.profile_data.subscription_active_till && 
                                    <Text fontSize={16} color="#586A7E">Next payment: {userDataContext.profile_data.subscription_active_till}</Text>
                                }
                            </Box>
                        }
                    </Flex>
                    
                        <Flex my={3} alignSelf={{ sm: "center", md: "flex-end" }} flexWrap="wrap" color="#586A7E">
                            
                            {userDataContext.profile_data && userDataContext.profile_data.plan == 'Freemium' && 
                                <Button height={30} mr={3} mb={2} onClick={() => navigate('/account/upgrade/')}>Upgrade plan</Button>
                            }
                            {userDataContext.profile_data && userDataContext.profile_data.plan != 'Freemium' && userDataContext.profile_data.stripe_customer_id != '' && 
                                <Button height={30} mr={3} mb={2} onClick={() => goPortal()}>Edit subscription plan, payment details and view payment history</Button>
                            }
                            <Button height={30} mr={3} mb={2} onClick={() => window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScj4oOH_bzIgzM7Kpd7weRVJGNzqq9yvvZJDScUhtLwu4Qeqw/viewform'}>Contact us for Custom Plan</Button>
                            {/* <Button height={30} variant="outline" onClick={() => goPortal()}>Edit plan</Button> */}
                        </Flex>
                </Flex>
                {userDataContext.profile_data && userDataContext.profile_data.billing != 'annually' && 
                    <Flex flexDirection={{ sm: "column", md: "row" }} justify={{ md: "space-between" }} align="center" borderWidth="1px" borderRadius="7px" w="100%" px={5} py={6} my={3}>
                        <Flex direction="column" mb={4} textAlign={{ sm: "center", md: "start" }}>
                            <Text color="#5C82F9" fontWeight="bold" fontSize="18px">Get 2 months free ✌</Text>
                            <Text color="#586A7E">Pay annually to get 2 months free.</Text>
                        </Flex>
                        <RouteLink to={'/account/upgrade/'}>
                            <Button colorScheme='brand' color="#fff" height={30}>View details</Button>
                        </RouteLink>
                    </Flex>
                }
                {/* {(userDataContext.profile_data.plan == 'Lite' || userDataContext.profile_data.plan == 'Professional') && 
                    <Flex flexDirection={{ sm: "column", md: "row" }} justify={{ md: "space-between" }} align="center" borderWidth="1px" borderRadius="7px" w="100%" px={5} py={6} my={3}>
                        <Flex direction="column" mb={4} textAlign={{ sm: "center", md: "start" }}>
                            <Text color="#5C82F9" fontWeight="bold" fontSize="18px">Need more listings?</Text>
                            <Text color="#586A7E">Try custom plan!</Text>
                        </Flex>
                        
                    </Flex>
                } */}
                {/* {userDataContext.profile_data.plan != 'Freemium' && userDataContext.profile_data.stripe_customer_id != '' && 
                    <Flex flexDirection={{ sm: "column", md: "row" }} justify={{ md: "space-between" }} align="center" borderWidth="1px" borderRadius="7px" w="100%" px={5} py={6} my={3}>
                        <Flex direction="column" mb={4} textAlign={{ sm: "center", md: "start" }}>
                            <Text fontWeight="bold" fontSize="18px">Invoices</Text>
                            <Text color="#586A7E">View your payment history</Text>
                        </Flex>
                        <Button variant="outline" color="#586A7E" height={30} onClick={() => goPortal()}>View billing history</Button>
                    </Flex>
                } */}

            </Stack>
            <Formik
                enableReinitialize
                initialValues={userData}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                    updateUser(values);
                    // alert(JSON.stringify(values, null, 2))
                }}>
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <Stack display="flex" flexDirection="column" align="center" mt={10} w="65%" mx="4%">
                        <Heading size="xl" alignSelf="start" mb={5}>Profile settings</Heading>
                        <Flex flexDirection={{ sm: "column", md: "row" }} w="100%">
                            <FormControl w="100%" mr={7}>
                                <FormLabel color="#686868">First name</FormLabel>
                                <Input size='md' w="100%" name='first_name' className='outline-0 border-[1px] border-[#ddd' onChange={handleChange} value={values.first_name} />
                                {errors.first_name && touched.first_name ? (<Text fontSize={14} color="#ff0000">{errors.first_name}</Text>) : null}
                            </FormControl>
                            <FormControl w="100%">
                                <FormLabel color="#686868">Last name</FormLabel>
                                <Input size='md' w="100%" name='last_name' className='outline-0 border-[1px] border-[#ddd' onChange={handleChange} value={values.last_name} />
                                {errors.last_name && touched.last_name ? (<Text fontSize={14} color="#ff0000">{errors.last_name}</Text>) : null}
                            </FormControl>
                        </Flex>

                        <FormControl w="100%" >
                            <FormLabel color="#686868">Email</FormLabel>
                            <Input size='md' w="100%" name='email' className='outline-0 border-[1px] border-[#ddd' onChange={handleChange} value={values.email} />
                            {errors.email && touched.email ? (<Text fontSize={14} color="#ff0000">{errors.email}</Text>) : null}
                        </FormControl>

                        <Box w="100%">
                            <hr className='mt-5' />
                        </Box>
                        <Button onClick={handleSubmit} colorScheme='brand' color="#fff" alignSelf={{ md: "flex-start" }}>Save</Button>
                    </Stack>
                )}
            </Formik>
        </Box >
    )
}

export default Account